import { Box, Stack, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button } from "rsuite";
import InvoiceReceiptsAuditLogTable from "./invoiceReceiptsAuditLogTable";
import TransferActionModal from "../../../../components/transferActionModal/transferActionModal";
import { capitalize } from "lodash";
import TransactionAuditLogTable from "./transactionAuditLog";
import { merchantApi } from "../../../../redux/api/apiConfig";
import { INCOMING_TRANSFER } from "../../../../ApiUrl";
import { toast } from "react-toastify";
import GroupedSelect from "../../../../components/GroupedSelect";

const InvoiceReceiptsAuditLog = ({ details }) => {
  const [currentTab, setCurrentTab] = useState("transfer audit log");
  const [viewAction, setViewAction] = useState(false);
  const [transactionData, setTransactionData] = useState(null);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [translist, setTranslist] = useState({
    count: 4,
    results: [
      {
        id: 123,
        date_time: "2020-01-01 05: 00 PM",
        action: "Company A",
        action_by: "Aditya Agarwal",
        checker: "Sushanta",
        amount: "550 USD",
        status: "complete",
      },
      {
        id: 345,
        date_time: "2020-01-01 05: 00 PM",
        action: "Company A",
        action_by: "Aditya Agarwal",
        checker: "Sushanta",
        amount: "450 USD",
        status: "complete",
      },
    ],
  });

  const PREVIEW_TABS = [
    {
      value: "transfer audit log",
      // icon: <Iconify icon={"ic:round-account-box"} width={20} height={20} />,
      component: (
        <InvoiceReceiptsAuditLogTable
          translist={translist}
          onViewAction={setViewAction}
        />
      ),
    },
    {
      value: "transaction audit log",
      // icon: <Iconify icon={"ic:round-account-box"} width={20} height={20} />,
      component: (
        <TransactionAuditLogTable
          translist={translist}
          onViewAction={setViewAction}
        />
      ),
    },
  ];

  async function getTransferTransactionList() {
    merchantApi
      .get(`${INCOMING_TRANSFER}${details?.id}/transactions/`)
      .then((response) => {
        if (response?.data) {
          setTransactionData(response?.data);
        }
      })
      .catch((error) => {
        toast.error(
          error?.data?.data?.message || "Error getting transaction list"
        );
      });
  }

  useEffect(() => {
    if (details) {
      getTransferTransactionList();
    }
  }, [details]);

  return (
    <Box sx={{ mt: 3 }}>
      <Box sx={{ mt: 3 }}>
        <Stack
          justifyContent={"space-between"}
          alignItems={{ md: "center", xs: "start" }}
          direction={{ md: "row", xs: "column" }}
          spacing={2}
          sx={{ background: "#f4f4f4", p: 2 }}
        >
          <Tabs
            allowScrollButtonsMobile
            variant="scrollable"
            scrollButtons="auto"
            value={currentTab}
            onChange={(e, newValue) => setCurrentTab(newValue)}
            sx={{ width: "100%" }}
          >
            {PREVIEW_TABS.map((tab) => (
              <Tab
                disableRipple
                key={tab.value}
                label={capitalize(tab.value)}
                iconPosition="start"
                icon={tab.icon}
                value={tab.value}
              />
            ))}
          </Tabs>
          {!(currentTab === "transfer audit log") && (
            <GroupedSelect transactionsDetails={transactionData} setSelectedPayment={setSelectedPayment}/>
          )}
        </Stack>
        {PREVIEW_TABS.map((tab) => {
          const isMatched = tab.value === currentTab;
          return isMatched && <Box key={tab.value}>{tab.component}</Box>;
        })}
      </Box>
      {viewAction && (
        <TransferActionModal
          isOpen={viewAction}
          onClose={() => setViewAction(false)}
        />
      )}
    </Box>
  );
};

export default InvoiceReceiptsAuditLog;
