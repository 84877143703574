import React, { useState, useEffect } from "react";
import {
  Select,
  MenuItem,
  ListSubheader,
  FormControl,
  InputLabel,
  useTheme,
} from "@mui/material";
import { fCurrency } from "../utils/formatNumber";

const GroupedSelect = ({ transactionsDetails, setSelectedPayment }) => {
  const [leagues, setLeagues] = useState({});
  const [selectedLeague, setSelectedLeague] = useState("");

  // Fetch data from API (mocked for this example)
  useEffect(() => {
    if (transactionsDetails) {
      setLeagues(transactionsDetails);
    }
  }, [transactionsDetails]);

  const handleChange = (event) => {
    setSelectedPayment(event.target.value);
  };

  console.log("selected payment", selectedLeague);

  return (
    <FormControl fullWidth>
      <InputLabel>Select Transactions</InputLabel>
      <Select
        value={selectedLeague}
        onChange={handleChange}
        label="Select Transactions"
        sx={{ backgroundColor: "#fff" }}
      >
        {Object.keys(leagues).map((group) => [
          // Render the group header
          <ListSubheader key={group} sx={{ color: "#000", fontSize: "16px" }}>
            {group === "contract_payment"
              ? "Contract Payment"
              : "Customer Payment"}
          </ListSubheader>,
          // Render the options in the group
          leagues[group].map((league) => (
            <MenuItem
              key={league.id}
              value={league.id}
              sx={{ fontSize: "14px" }}
            >
              {group === "contract_payment"
                ? `${league.lender?.name} - ${fCurrency(league?.amount)}`
                : `${league.supplier?.name} - ${fCurrency(league?.amount)}`}
            </MenuItem>
          )),
        ])}
      </Select>
    </FormControl>
  );
};

export default GroupedSelect;
