import './wdyr';
import React from 'react';

// import ThemeProvider from './theme';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import {
  getConfigData,
  getCurrencyTypes,
  getDepositTypes,
  getPurposeTypes,
  getRemittanceStatuses,
  getRedeemStatuses,
  getKycDocTypes,
  getRemittanceTypes,
  getAccountTypes,
  getCountriesData,
} from './redux/actions/config';
import { getPermissions, getProfileData } from './redux/actions/profile';
import { getNotificationHistory } from './redux/actions/notification';
import { useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '@fortawesome/fontawesome-free/css/all.css';
import 'react-datetime/css/react-datetime.css';
import axios from 'axios';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

import HomePage from './pages/HomePage';
import ScrollToTop from './components/ScrollToTop';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useEffect } from 'react';
import { getWallets, getCoins } from './redux/actions/wallet';

const dotenv = require('dotenv');

dotenv.config();
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE);

const theme = createTheme({
  typography: {
    fontFamily: ['Inter', 'sans-serif'].join(','),
  },
  overrides: {
    MuiInputLabel: {
      root: {
        '&$focused': {
          // color: '#FCBD00',
        },
        '&$error': {
          // color: 'red',
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        // borderRadius: '8px',
        '& .MuiOutlinedInput-notchedOutline': {
          // borderColor: '#0B0523',
          // height: '60px',
        },
        '&.MuiOutlinedInput-root': {
          borderRadius: '8px',
          // height: '56px',
        },
        '&$focused $notchedOutline': {
          borderColor: '#0B0523',
        },
      },
    },
  },
});

export default function App() {
  const dispatch = useDispatch();

  const token = localStorage.getItem('accessToken');

  useEffect(() => {
    if (token) {
      hydrateStore();
    }
  }, []);

  const hydrateStore = async () => {
    dispatch(getProfileData());
    dispatch(getPermissions());
    // dispatch(getCurrencyTypes());
    // dispatch(getDepositTypes());
    // dispatch(getPurposeTypes());
    // dispatch(getRemittanceStatuses());
    // dispatch(getRedeemStatuses());
    // dispatch(getKycDocTypes());
    // dispatch(getRemittanceTypes());
    // dispatch(getCountriesData());
    // dispatch(getWallets());
    // dispatch(getCoins());
  };

  return (
    // <Provider store={store}>
    <div>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ThemeProvider theme={theme}>
          <Elements stripe={stripePromise}>
            <ScrollToTop />
            <HomePage stripe={stripePromise} />
            <ToastContainer />
          </Elements>
        </ThemeProvider>
      </LocalizationProvider>
    </div>
    // </Provider>
  );
}
