import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { startCase, toLower } from "lodash";
import {
  Box,
  Stack,
  Typography,
  LinearProgress,
  Avatar,
  Tooltip,
  Tabs,
  Tab,
  IconButton,
} from "@mui/material";
import {
  formatWithoutTimezone,
  rowsPerPageOptions,
} from "../../../../utils/common";

import { DataGrid } from "@mui/x-data-grid";
import { formatCurrency, tableCellStyle } from "../../../../utils/common";

import createAvatar from "../../../../utils/createAvatar";
import { Label, ButtonGhost } from "../../../../components";
import { useDispatch, useSelector } from "react-redux";
import { UPDATE_INWARD_REMITTANCE } from "../../../../redux/actionTypes";
import InfoIcon from "@mui/icons-material/Info";
import { Routes } from "../../../../routes";

const points = ["Paid By : None  →  Walmart Inc.", "POBO : None  →  XYZ Inc."];

export default function TransactionAuditLogTable({
  translist,
  fetchTranslistLoading,
  onViewAction,
  //   counts,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const allStatus = useSelector(
    (state) => state.configReducer.remittanceStatuses
  );
  // State to control the visibility of all points
  const [showAllPoints, setShowAllPoints] = useState(false);

  // Function to handle "See more" for the second point and show all points
  const handleSeeMore = () => {
    setShowAllPoints(true); // Show all points when clicked
  };
  const filters = useSelector((state) => state.filters.remittances.inward);

  const handleChangePage = (newPage) => {
    dispatch({
      type: UPDATE_INWARD_REMITTANCE,
      payload: { currentPage: newPage },
    });
  };

  const handleChangeRowsPerPage = (newSize) => {
    dispatch({
      type: UPDATE_INWARD_REMITTANCE,
      payload: { currentPage: 0, pageSize: newSize },
    });
  };

  const createDataRow = (transaction) => {
    // const type = transaction.type;
    return {
      id: transaction?.id,
      date_time: transaction?.date_time,
      action: transaction?.action,
      action_by: transaction?.action_by,
      checker: transaction?.checker,
      amount: transaction?.amount,
      status: transaction?.status,
    };
  };

  const rows =
    translist?.results?.length > 0
      ? translist.results.map((transaction) => createDataRow(transaction))
      : [];

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
      minWidth: 120,
      // maxWidth: 200,
      headerClassName: "header",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Tooltip sx={{ cursor: "default" }} title={params.formattedValue}>
            <Typography variant="body2" style={tableCellStyle}>
              {params.formattedValue}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "date_time",
      headerName: "Date/Time",
      flex: 1,
      minWidth: 200,
      // maxWidth: 200,
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <Typography variant="body2" style={tableCellStyle}>
            {params.formattedValue}
          </Typography>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      minWidth: 400,
      // maxWidth: 200,
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <Box style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
            <ul>
              <li>
                <Typography style={{ fontSize: "12px" }}>
                  {points[0]}
                </Typography>
              </li>
              <li>
                <Typography style={{ fontSize: "12px" }}>
                  {showAllPoints ? (
                    points[1] // Show full text if "See more" is clicked
                  ) : (
                    <>
                      {points[1].slice(0, 20)}...{" "}
                      <button onClick={handleSeeMore}>See more</button>
                    </>
                  )}
                </Typography>
              </li>
              {showAllPoints &&
                points.slice(2).map((point, index) => (
                  <li key={index + 2}>
                    <Typography style={{ fontSize: "12px" }}>
                      {point}
                    </Typography>
                  </li>
                ))}
            </ul>
          </Box>
        );
      },
    },
    {
      field: "action_by",
      headerName: "Action By",
      flex: 1,
      minWidth: 200,
      // maxWidth: 200,
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <Typography variant="body2" style={tableCellStyle}>
            {params.formattedValue}
          </Typography>
        );
      },
    },
    {
      field: "checker",
      headerName: "Checker",
      flex: 1,
      minWidth: 250,
      // maxWidth: 200,
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <Typography variant="body2" style={tableCellStyle}>
            {`${params.formattedValue}`}
          </Typography>
        );
      },
    },

    {
      field: "amount",
      headerName: "Amount",
      flex: 1,
      minWidth: 150,
      // maxWidth: 200,
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <Typography variant="body2" style={tableCellStyle}>
            {params.formattedValue}
          </Typography>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 200,
      // maxWidth: 200,
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <Label
            variant={"ghost"}
            color={
              params.formattedValue.toLowerCase() === "complete"
                ? "complete"
                : params.formattedValue.toLowerCase() === "rejected"
                ? "rejected"
                : "application submitted"
            }
            sx={{ textTransform: "capitalize" }}
          >
            {params.formattedValue}
          </Label>
        );
      },
    },
  ];

  const onStatusFilterChanges = (newStatus) => {
    let status;
    console.log(newStatus);
    if (newStatus !== "") {
      status = allStatus.find((status) => status.value === newStatus) ?? "";
    }
    // setFilterStatus(newStatus);
    dispatch({
      type: UPDATE_INWARD_REMITTANCE,
      payload: { currentPage: 0, status: null, statusValue: newStatus },
    });
  };

  return (
    <>
      <Box
        sx={{
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 0px 8px 0px",
          borderRadius: "8px",
          // height: 825,
          width: "100%",
          "& .MuiDataGrid-virtualScrollerRenderZone": {
            "& .MuiDataGrid-row": {
              // backgroundColor: 'rgba(235, 235, 235, .7)',
              fontWeight: 500,
              color: "#667085",
              fontFamily: "Inter, sans-serif",
              fontSize: "14px",
            },
          },
          "& .header": {
            backgroundColor: "#F4F4F4",
            "& div": {
              "& div": {
                "& div": {
                  "& div": {
                    fontSize: "14px",
                    fontWeight: 600,
                    fontFamily: "Inter, sans-serif",
                  },
                },
              },
            },
          },
        }}
      >
        <DataGrid
          autoHeight
          sx={{
            borderRadius: "0 0 8px 8px",
            // boxShadow: 'rgba(99, 99, 99, 0.2) 0px 0px 8px 0px',
            width: "100%",
            cursor: "pointer",
          }}
          components={{
            LoadingOverlay: LinearProgress,
          }}
          onRowClick={(row) => {
            // console.log('row data', `${Routes.IncomingTransferDetailView.path}${row?.row?.id}`);
            history.push({
              pathname: `${Routes.IncomingTransferDetailView.path}${row?.row?.id}`,
            });
          }}
          rows={rows}
          columns={columns}
          rowHeight={80}
          disableColumnFilter={true}
          disableColumnMenu={true}
          pagination
          paginationMode="server"
          rowsPerPageOptions={rowsPerPageOptions}
          page={filters.currentPage}
          rowCount={translist.count}
          pageSize={filters.pageSize}
          onPageChange={(newPage) => handleChangePage(newPage)}
          onPageSizeChange={(newSize) => handleChangeRowsPerPage(newSize)}
          loading={fetchTranslistLoading}
          get
        />
      </Box>
    </>
  );
}
