import React, { useState } from "react";
import {
  Box,
  Card,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Highlight, HighlightSkeleton } from "../../../reports/components";
import InfoCard from "../../../../components/infoCard/InfoCard";
import { Iconify, PreviewContainer, PreviewItem } from "../../../../components";
import { EditMonthlyFeesModal } from "../../../configurations/fees/feesComponents";
import { capitalize } from "lodash";
import { format } from "date-fns";
import SupplierDocumentDetails from "../../supplierDocumentDetail";
import KycKybStatusUpdate from "../../kyckybStatusUpateForm";

const BusinessDetails = ({ client, getClients }) => {
  const [highlightLoading, setHighlightLoading] = useState(false);
  const [showEditMonthlyFeesModal, setShowEditMonthlyFeesModal] =
    useState(false);
  const [highlights, setHighlights] = useState([
    { heading: "Total Pending Liens", data: 23, meta: { color: "green" } },
    { heading: "Total Settled Liens", data: 23, meta: { color: "brown" } },
    {
      heading: "Total Borrowings Lifetime",
      data: 23,
      meta: { color: "brown" },
    },
  ]);

  const toggleEditMonthlyFeesModal = () => {
    setShowEditMonthlyFeesModal((prev) => !prev);
  };

  const businessDetails = (
    <>
      <PreviewItem title={"Business Name"} content={client?.business_name} />
      <PreviewItem title={"Full Name"} content={client?.primary_contact_name} />
      <PreviewItem title={"Country"} content={client?.country?.name} />
      <PreviewItem title={"Province"} content={client?.province?.name} />
      <PreviewItem title={"City"} content={client?.city?.name} />
      <PreviewItem
        title={"Designation"}
        content={client?.adminshareholder?.designation}
      />
      <PreviewItem
        title={"Business Category"}
        content={client?.category?.parent_category?.name}
      />
      <PreviewItem
        title={"Business Sub Category"}
        content={client?.category?.name}
      />
      <PreviewItem title={"Occupation Type"} content={client?.occupation} />
      <PreviewItem title={"Registration Number"} content={client?.company_id} />
      <PreviewItem title={"Postal Code"} content={client?.postal_code} />
      <PreviewItem title={"Timezone"} content={client?.time_zone?.display} />
      <PreviewItem
        title={"Date of Incorporation"}
        content={client?.incorporation_date}
      />
      <PreviewItem title={"Website"} content={client?.company_url} />
      <PreviewItem
        title={"KYB Status"}
        content={client?.kyb_status?.display}
      />
      <PreviewItem title={"Address"} content={client?.address} />
    </>
  );

  const personalDetails = (
    <>
      <PreviewItem
        title={"First Name"}
        content={client?.adminshareholder?.first_name}
      />
      <PreviewItem
        title={"Last Name"}
        content={client?.adminshareholder?.last_name}
      />
      <PreviewItem
        title={"Date of Birth"}
        content={client?.adminshareholder?.dob}
      />
      <PreviewItem
        title={"Primary Phone Number"}
        content={client?.adminshareholder?.primary_phone_number}
      />
      <PreviewItem
        title={"Secondary Phone Number"}
        content={client?.adminshareholder?.secondary_phone_number}
      />
      <PreviewItem
        title={"Country"}
        content={client?.adminshareholder?.country?.name}
      />
      <PreviewItem
        title={"Province"}
        content={client?.adminshareholder?.province?.name}
      />
      <PreviewItem
        title={"City"}
        content={client?.adminshareholder?.city?.name}
      />
      <PreviewItem
        title={"Postal Code"}
        content={client?.adminshareholder?.postal_code}
      />
      <PreviewItem
        title={"Residence Address"}
        content={client?.adminshareholder?.address}
      />
      <PreviewItem
        title={"Passport Number"}
        content={client?.adminshareholder?.passport_number}
      />
    </>
  );
  return (
    <Box sx={{ marginTop: "1.5rem" }}>
      <Stack
        direction="row"
        sx={{ mb: 3, gap: 3, flexDirection: { xs: "column", sm: "row" } }}
      >
        {/* {highlightLoading
              ? [...Array(1)].map((a) => <HighlightSkeleton />)
              : highlights.map((highlight, i) => {
                  return (

                    <Highlight
                      key={highlight.heading}
                      title={highlight.heading}
                      subTitle={highlight.sub_heading}
                      // total={translist.count}
                      percent={50}
                      value={highlight.data}
                      icon={highlight?.meta?.icon}
                      color={highlight?.meta?.color}
                    />
                  );
                })} */}
        {/* <InfoCard
                    title={'Monthly Fee'}
                    number={'24 USD'}
                    icon="la:hand-holding-usd"
                    color={'info'}
                    action={<IconButton onClick={toggleEditMonthlyFeesModal} ><Iconify  icon='eva:edit-fill'/></IconButton>}
                    /> */}
      </Stack>

      <Accordion sx={{ width: "100%" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Business Info</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <PreviewContainer>{businessDetails}</PreviewContainer>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              mt: 1,
            }}
          >
            <KycKybStatusUpdate
              data={client?.kyb_status}
              id={client?.kyb_status?.id}
              isKyb
            />
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ width: "100%" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Personal Info</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <PreviewContainer>{personalDetails}</PreviewContainer>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ width: "100%" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Shareholders Info</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {client?.shareholders?.map((item, i) => {
            return (
              <>
                <PreviewContainer key={i} title="Shareholder Data">
                  <PreviewItem
                    title={"First Name"}
                    content={item?.first_name}
                  />
                  <PreviewItem title={"Last Name"} content={item?.last_name} />
                  <PreviewItem title={"Date of Birth"} content={item?.dob} />
                  <PreviewItem title={"Email"} content={item?.email} />
                  <PreviewItem
                    title={"Primary Phone Number"}
                    content={item?.primary_phone_number}
                  />
                  <PreviewItem
                    title={"Ownership"}
                    content={`${item?.ownership}%`}
                  />
                </PreviewContainer>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    mt: 1,
                    mb: 2,
                  }}
                >
                  <KycKybStatusUpdate
                    data={item?.kyc}
                    id={item?.kyc?.id}
                  />
                </Box>
              </>
            );
          })}
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ width: "100%" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Documents</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <SupplierDocumentDetails client={client} getClient={getClients} />
        </AccordionDetails>
      </Accordion>
      {/* <Accordion sx={{ width: "100%" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Personal Docs</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>Personal Docs</Typography>
        </AccordionDetails>
      </Accordion> */}

      {showEditMonthlyFeesModal && (
        <EditMonthlyFeesModal
          onClose={toggleEditMonthlyFeesModal}
          isOpen={showEditMonthlyFeesModal}
          isEdit={true}
          slabToEdit={{}}
          onSubmit={() => {
            toggleEditMonthlyFeesModal();
          }}
        />
      )}
    </Box>
  );
};

export default BusinessDetails;
