import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { toast } from "react-toastify";
import "rsuite/dist/styles/rsuite-default.css";

import * as API from "../../ApiUrl";
import { merchantApi } from "../../redux/api/apiConfig";

import { format } from "date-fns";
import {
  Box,
  Divider,
  MenuItem,
  Stack,
  TextField,
  InputAdornment,
  Autocomplete,
  Card,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { Routes } from "../../routes";
import usePageTitle from "../../hooks/usePageTitle";
import { reportTimePeriods } from "../reports/common";
import { formatDate } from "../../utils/common";
import { ButtonPrimary, ButtonResetFilter, Iconify } from "../../components";
import { StyledDatePicker } from "../../components/datePicker";
import {
  FilterDropdown,
  Highlight,
  HighlightSkeleton,
} from "../reports/components";
import {
  CLEAR_INWARD_REMITTANCE,
  UPDATE_INWARD_REMITTANCE,
} from "../../redux/actionTypes";
import ClosureTxnsModal from "../../components/closureTxnsModal";
import { InvoiceReceiptsTable } from "./components";
import ContractFilesModal from "../../components/contractFilesModal/ContractFilesModal";
import CreateContractModal from "../../components/createContractModal/CreateContractModal";
import SettlementTransactionsModal from "../../components/settlementTransactions/settlementTransactions";

function InvoiceReceipts() {
  const [translist, setTranslist] = useState([]);
  const [fetchTranslistLoading, setFetchTranslistLoading] = useState(false);
  const [filters, setFilters] = useState({
    currentPage: 0,
    pageSize: 7,
  });
  const [highlightLoading, setHighlightLoading] = useState(false);
  const [highlights, setHighlights] = useState([
    { heading: "Total Pending Liens", data: 23, meta: { color: "green" } },
    { heading: "Total Settled Liens", data: 23, meta: { color: "brown" } },
    {
      heading: "Total Borrowings Lifetime",
      data: 23,
      meta: { color: "brown" },
    },
  ]);
  const [showContractFilesModal, setShowContractFilesModal] = useState(true);
  const [showClosureTxnsModal, setShowClosureTxnsModal] = useState(false);
  const [counts, setCounts] = useState({
    pending: 0,
    processed: 0,
    all: 0,
  });
  const [showCreateContractModal, setShowCreateContractModal] = useState("");
  const { configReducer } = useSelector((state) => state);
  // const { coins = [] } = walletsReducer;
  const { remittanceStatuses: allStatuses = [] } = configReducer;

  const dispatch = useDispatch();
  const history = useHistory();
  usePageTitle("Incoming Transfer", "View Transfers");

  const handleClearFilters = () => {
    dispatch({ type: CLEAR_INWARD_REMITTANCE });
  };

  async function getIncomingTransferList(filters) {
    setFetchTranslistLoading(true);
    const { currentPage, pageSize } = filters;
    let params = {
      offset: currentPage * pageSize,
      limit: pageSize,
    };

    merchantApi
      .get(`${API.INCOMING_TRANSFER}`, {
        params,
      })
      .then((response) => {
        setFetchTranslistLoading(false);
        if (response.data) {
          setTranslist(response.data);
        }
      })
      .catch((error) => {
        toast.error(
          error?.data?.data?.message || "Error getting contracts list"
        );
        setFetchTranslistLoading(false);
      });
  }

  useEffect(() => {
    getIncomingTransferList(filters);
  }, [filters]);

  const viewDetailsHandler = (id) => {
    // history.push(Routes.IncomingPayment.path + `/${id}`);
    toggleClosureTxnsModal();
  };
  const viewContractFilesHandler = (id) => {
    // history.push(Routes.IncomingPayment.path + `/${id}`);
    setShowContractFilesModal();
  };

  const toggleClosureTxnsModal = () => {
    console.log("owncowieociwnoec");
    setShowClosureTxnsModal((state) => !state);
  };

  const toggleCreateContractModal = () => {
    setShowCreateContractModal((state) => !state);
  };

  return (
    <>
      <Box sx={{ padding: "0 24px 24px" }}>
        {/* <Stack
          direction={"row"}
          justifyContent={"flex-end"}
          width={"100%"}
          mb={2}
        >
          <ButtonPrimary onClick={toggleCreateContractModal}>
            New Contract
          </ButtonPrimary>
        </Stack> */}
        {/* {highlightLoading || highlights?.length > 0 ? (
          <Card sx={{ width: "100%" }}>
            <Stack
              direction="row"
              divider={
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ borderStyle: "dashed" }}
                />
              }
              sx={{ py: 2 }}
            >
              {highlightLoading
                ? [...Array(1)].map((a) => <HighlightSkeleton />)
                : highlights.map((highlight, i) => {
                    return (
                      <Highlight
                        key={highlight.heading}
                        title={highlight.heading}
                        subTitle={highlight.sub_heading}
                        // total={translist.count}
                        percent={50}
                        value={highlight.data}
                        icon={highlight?.meta?.icon}
                        color={highlight?.meta?.color}
                      />
                    );
                  })}
            </Stack>
          </Card>
        ) : null} */}
        <Stack
          sx={{
            overflowX: { xs: "auto" },
            overflowY: { xs: "visible", md: "unset" },
            padding: "5px 0",
          }}
          direction={"row"}
          justifyContent={"space-between"}
          gap={1}
          mt={2}
          width={"100%"}
        >
          <Stack
            sx={{ flexDirection: "row", gap: 1 }}
            divider={
              <Divider
                orientation="vertical"
                flexItem
                sx={{ borderStyle: "dashed" }}
              />
            }
          >
            <Stack sx={{ minWidth: "150px", flex: 1 }}>
              <FilterDropdown
                name="timeperiod"
                type="text"
                label="Timeperiod"
                options={reportTimePeriods}
                onChange={(e) => {
                  const name = e.target.value;
                  const period = reportTimePeriods.find(
                    (report) => report.name === name
                  );

                  let toValue;
                  if (filters.calenderValue[1]) {
                    toValue =
                      new Date(period.from) > new Date(filters.calenderValue[1])
                        ? null
                        : filters.calenderValue[1];
                  } else {
                    toValue = null;
                  }
                  dispatch({
                    type: UPDATE_INWARD_REMITTANCE,
                    payload: {
                      calenderValue: [new Date(period.from), toValue],
                      period,
                    },
                  });
                }}
                value={filters?.period?.name}
                size={"small"}
                render={(period, i) => (
                  <MenuItem key={period.name} value={period.name}>
                    {period.name}
                  </MenuItem>
                )}
              />
            </Stack>
            <Stack sx={{ flexDirection: "row", gap: 1 }}>
              <Stack sx={{ width: "176px", flex: 1 }}>
                <StyledDatePicker
                  // value={filters.calenderValue[0]}
                  disableFuture
                  format={"dd/MM/yyyy"}
                  onChange={(newValue) => {
                    console.log(newValue);
                  }}
                />
              </Stack>

              <Stack sx={{ width: "176px", flex: 1 }}>
                <StyledDatePicker
                  // value={filters.calenderValue[1]}
                  disableFuture
                  // minDate={filters.calenderValue[0] ?? undefined}
                  format={"dd/MM/yyyy"}
                  onChange={(newValue) => {
                    console.log(newValue);
                  }}
                />
              </Stack>
            </Stack>

            <Stack sx={{ flexDirection: "row", gap: 1, flex: 1 }}>
              <Stack sx={{ minWidth: "200px", flex: 1 }}>
                <TextField
                  fullWidth
                  size="small"
                  value={filters.search}
                  onChange={(e) =>
                    dispatch({
                      type: UPDATE_INWARD_REMITTANCE,
                      payload: {
                        search: e.target.value,
                      },
                    })
                  }
                  placeholder="Search..."
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Iconify
                          icon="eva:search-fill"
                          sx={{ color: "text.disabled" }}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Stack>
            </Stack>

            <Stack sx={{ minWidth: "200px", flex: 1 }}>
              <Autocomplete
                id="status"
                value={filters.status}
                onChange={(e, newValue) => {
                  console.log(newValue);
                  dispatch({
                    type: UPDATE_INWARD_REMITTANCE,
                    payload: {
                      status: newValue ?? null,
                      statusValue: "",
                    },
                  });
                }}
                options={allStatuses}
                getOptionLabel={(option) => option?.sender_name}
                sx={{ flexGrow: 1, width: "100%" }}
                renderInput={(params) => {
                  return (
                    <TextField
                      placeholder="Select Status"
                      {...params}
                      size="small"
                    />
                  );
                }}
              />
            </Stack>
          </Stack>
          <Stack sx={{ flexDirection: "row", gap: 1 }}>
            <ButtonResetFilter onClick={handleClearFilters} />
          </Stack>
        </Stack>
        <InvoiceReceiptsTable
          translist={translist}
          fetchTranslistLoading={fetchTranslistLoading}
          viewDetails={viewDetailsHandler}
          viewContractFiles={viewContractFilesHandler}
          counts={counts}
        />
        <SettlementTransactionsModal
          isOpen={showClosureTxnsModal}
          onClose={toggleClosureTxnsModal}
        />
        {/* <ContractFilesModal
          isOpen={showContractFilesModal}
          onClose={toggleContractFilesModal}
        /> */}
        <CreateContractModal
          isOpen={showCreateContractModal}
          onClose={toggleCreateContractModal}
        />
      </Box>
    </>
  );
}

export default InvoiceReceipts;
