import React from "react";
import {
  Iconify,
  Label,
  PreviewContainer,
  PreviewItem,
} from "../../../../components";
import { Box, Stack } from "@mui/material";
import UpdateInvoiceReceiptInfo from "../updateInvoiceReceiptInfo/updateInvoiceReceiptInfo";
import { Button } from "rsuite";
import { formatWithoutTimezone } from "../../../../utils/common";
import { fCurrency } from "../../../../utils/formatNumber";

const InvoiceReceiptInfo = ({ details, supplierList, getTransferDetail }) => {
  console.log("transfer details", details?.created_date);
  const businessDetails = (
    <>
      <PreviewItem
        title={"Date of Receipt"}
        content={
          details?.created_date
            ? formatWithoutTimezone(details?.created_date, "dd MMM yyyy")
            : "--"
        }
      />
      <PreviewItem title={"Paid By"} content={details?.paid_by} />
      <PreviewItem title={"COBO"} content={details?.cobo?.name} />
      <PreviewItem title={"Amount"} content={fCurrency(details?.amount)} />
      <PreviewItem
        title={"Status"}
        content={
          <>
            <Label
              variant={"ghost"}
              color={
                details?.status?.value === "settled"
                  ? "complete"
                  : details?.status?.value === "unsettled"
                  ? "rejected"
                  : "application submitted"
              }
              sx={{ textTransform: "capitalize" }}
            >
              {details?.status?.display}
            </Label>
          </>
        }
      />
    </>
  );
  return (
    <Box sx={{ mt: 3, mb: 3 }}>
      <Stack direction={"row"} justifyContent={"flex-end"} sx={{ mb: 3 }}>
        <Button
          className={`btn action-button primary_color text-white`}
          appearance="primary"
          type={"button"}
          style={{
            maxWidth: "300px",
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
          // disabled={!formik.isValid}
          // loading={loading}
        >
          <Iconify icon="mdi:broadcast" width={22} />
          Broadcast For Unmapped
        </Button>
      </Stack>
      <PreviewContainer>{businessDetails}</PreviewContainer>
      <UpdateInvoiceReceiptInfo details={details} supplierList={supplierList} getTransferDetail={getTransferDetail}/>
    </Box>
  );
};

export default InvoiceReceiptInfo;
