import React, { useState, useEffect, useCallback } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { Iconify, Label } from "../../components";
import { capitalize } from "lodash";
import {
  Box,
  Tab,
  Tabs,
  Stack,
  Skeleton,
  styled,
  Typography,
  Tooltip,
  Card,
} from "@mui/material";
import back from "../../assets/img/icons/back.svg";
import profile from "../../assets/img/team/vanilla-profile.jpg";
import Image from "../../components/image/Image";
import { toast } from "react-toastify";
import { merchantApi } from "../../redux/api/apiConfig";
import {
  REMITTANCE_INCOMING,
  REMITTANCE_OUTGOING,
  SUPPLIER,
  SUPPLIER_BUS,
} from "../../ApiUrl";
import usePageTitle from "../../hooks/usePageTitle";
import { BusinessDetails, Transactions } from "./components";
import ProgrammedLiens from "./components/programmedLiens";
import { CustomAvatar } from "../../components/customAvatar";
import signinBg from "../../assets/img/signinBg.png";
import UpdateSupplierStatusModal from "./components/updateSupplierStatusModal";
import Fees from "./components/fees";
import { statusColors } from "../../utils/common";
import KycKybStatusHistory from "./components/kycKybStatusHistory/kycKybStatusHistory";

const StyledRoot = styled("div")(({ theme }) => ({
  "&:before": {
    backdropFilter: "blur(6px)",
    WebkitBackdropFilter: "blur(6px)",
    // backgroundColor: alpha('#000000', 1),
    top: 0,
    zIndex: 9,
    content: "''",
    width: "100%",
    height: "100%",
    position: "absolute",
  },
}));

const StyledInfo = styled("div")(({ theme }) => ({
  left: 0,
  right: 0,
  zIndex: 99,
  position: "absolute",
  marginTop: theme.spacing(1),
  [theme.breakpoints.up("md")]: {
    right: "auto",
    display: "flex",
    alignItems: "center",
    left: theme.spacing(3),
    bottom: theme.spacing(5),
  },
}));

const SupplierDetails = ({ type }) => {
  const [currentTab, setCurrentTab] = useState("business details");
  const [supplier, setSupplier] = useState(null);
  const [client, setClient] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [statusUpdateLoading, setStatusUpdateLoading] = useState(false);

  const location = useLocation();
  const history = useHistory();
  // const params = useParams();
  const query = new URLSearchParams(location.search);

  const filters = Object.fromEntries(query);
  const { id } = filters;
  const isInward = location.pathname.includes("inward");

  usePageTitle("Supplier Details");

  const toggleStatusModal = () => {
    console.log("owncowieociwnoec");
    setShowStatusModal((state) => !state);
  };

  const updateStatusClickHandler = (transaction) => {
    console.log("hereprmvpekrmvopewkrm");
    // setTransactionData(transaction);
    toggleStatusModal();
  };

  const updateClickHandler = async (status) => {
    const url =
      status === "active"
        ? `${SUPPLIER}${id}/activate/`
        : `${SUPPLIER}${id}/deactivate/`;
    try {
      setStatusUpdateLoading(true);
      const response = await merchantApi.post(url);
      toast.success("Supplier Status Updated");
    } catch (error) {
      toast.error(error?.data?.data?.message || "Something went wrong");
      setStatusUpdateLoading(false);
    }
  };

  async function getClients() {
    try {
      setLoading(true);
      const response = await merchantApi.get(`${SUPPLIER_BUS}${id}/`);
      console.log(response);
      setClient(response.data);
      setLoading(false);
    } catch (error) {
      toast.error(error?.data?.data?.message || "Something went wrong");
      setLoading(false);
    }
  }

  const getTransasction = useCallback(async () => {
    const URL = isInward ? REMITTANCE_INCOMING : REMITTANCE_OUTGOING;
    try {
      setLoading(true);
      const response = await merchantApi.get(`${URL}${id}/`);
      setSupplier(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error?.data?.data?.message || "Something went wrong");
    }
  }, [id, isInward]);

  useEffect(() => {
    getClients();
    if (location?.state?.isEdit) {
      setCurrentTab("documents");
    }
  }, [id, getTransasction, location]);

  const PREVIEW_TABS = [
    {
      value: "business details",
      icon: <Iconify icon={"ic:round-account-box"} width={20} height={20} />,
      component: <BusinessDetails client={client} getClients={getClients} />,
    },
    {
      value: "KYB/KYC Status History",
      icon: (
        <Iconify icon={"material-symbols:history"} width={20} height={20} />
      ),
      component: <KycKybStatusHistory client={client} getClients={getClients} />,
    },
    // {
    //   value: "transactions",
    //   icon: <Iconify icon={"ion:documents"} width={20} height={20} />,
    //   component: <Transactions />,
    // },
    // {
    //   value: "programmed contracts",
    //   icon: (
    //     <Iconify
    //       icon={"material-symbols:history-rounded"}
    //       width={20}
    //       height={20}
    //     />
    //   ),
    //   component: <ProgrammedLiens />,
    // },
    // {
    //   value: "fees",
    //   icon: <Iconify icon={"la:hand-holding-usd"} width={20} height={20} />,
    //   component: <Fees />,
    // },
  ];

  const backClickHandler = () => {
    history.goBack();
  };

  console.log("supplier data", supplier);

  return (
    <Box sx={{ padding: "24px " }}>
      {loading ? (
        <Skeleton variant="rounded" animation={"wave"} height={1500} />
      ) : (
        <>
          <Stack justifyContent={"space-between"} direction={"row"}>
            <span onClick={backClickHandler} className="back mb-4">
              <img src={back} alt="back" />
              <span>Back</span>
            </span>
          </Stack>
          <Card
            sx={{
              mb: 3,
              height: 200,
              position: "relative",
            }}
          >
            <StyledRoot>
              <StyledInfo>
                <Stack sx={{ position: "relative", width: "fit-content" }}>
                  <CustomAvatar
                    src={profile}
                    alt={client?.business_name}
                    name={client?.business_name || "Supplier Name"}
                    sx={{
                      mx: "auto",
                      borderWidth: 2,
                      borderStyle: "solid",
                      borderColor: "common.white",
                      width: { xs: 80, md: 128 },
                      height: { xs: 80, md: 128 },
                    }}
                  />
                  {/* <Stack sx={{ textTransform: "capitalize", position:'absolute', top:0, right:0, borderRadius:'50%',padding:1, backgroundColor: statusColors.bgColors["application submitted"] }}>
                    <Typography variant="caption" sx={{color: statusColors.colors["application submitted"]}}>
                      VIP
                    </Typography>
                  </Stack> */}
                  <Label
                    sx={{
                      textTransform: "capitalize",
                      position: "absolute",
                      bottom: 0,
                      right: 0,
                      opacity: 1,
                    }}
                    backgroundColor="#67c3ff"
                  >
                    VIP
                  </Label>
                </Stack>
                <Box
                  sx={{
                    ml: { md: 3 },
                    mt: { xs: 1, md: 0 },
                    color: "common.white",
                    textAlign: { xs: "center", md: "left" },
                  }}
                >
                  <Stack
                    direction={"column"}
                    alignItems={"flex-start"}
                    justifyContent={"center"}
                    spacing={1}
                  >
                    <Typography
                      variant="h4"
                      sx={{ color: "white" }}
                    >{`${capitalize(
                      client?.business_name || "Supplier"
                    )}`}</Typography>
                    <Stack
                      direction={"row"}
                      gap={1}
                      onClick={toggleStatusModal}
                      alignItems={"center"}
                    >
                      <Tooltip title={supplier?.status} placement="top">
                        <Label
                          variant={"ghost"}
                          color={
                            client?.status === "active"
                              ? "complete"
                              : "application submitted"
                          }
                          // backgroundColor={"complete"}
                          sx={{ textTransform: "capitalize", fontSize: "14px" }}
                        >
                          {client?.status}
                        </Label>
                      </Tooltip>
                      <Iconify icon="eva:edit-fill" width={20} />
                    </Stack>
                  </Stack>
                </Box>
              </StyledInfo>

              <Image
                alt="cover"
                src={signinBg}
                sx={{
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  position: "absolute",
                  objectFit: "cover",
                }}
              />
            </StyledRoot>
          </Card>

          <Stack
            justifyContent={"space-between"}
            alignItems={{ md: "center", xs: "start" }}
            direction={{ md: "row", xs: "column" }}
            spacing={2}
          >
            <Tabs
              allowScrollButtonsMobile
              variant="scrollable"
              scrollButtons="auto"
              value={currentTab}
              onChange={(e, newValue) => setCurrentTab(newValue)}
            >
              {PREVIEW_TABS.map((tab) => (
                <Tab
                  disableRipple
                  key={tab.value}
                  label={capitalize(tab.value)}
                  iconPosition="start"
                  icon={tab.icon}
                  value={tab.value}
                />
              ))}
            </Tabs>
          </Stack>
          {PREVIEW_TABS.map((tab) => {
            const isMatched = tab.value === currentTab;
            return isMatched && <Box key={tab.value}>{tab.component}</Box>;
          })}
        </>
      )}
      {showStatusModal && (
        <UpdateSupplierStatusModal
          isOpen={showStatusModal}
          onClose={toggleStatusModal}
          updateClickHandler={(status) => {
            console.log("upsate status", status);
            updateClickHandler(status);
          }}
          statusUpdateLoading={statusUpdateLoading}
          status={client?.status}
        />
      )}
    </Box>
  );
};

export default SupplierDetails;
