import React, { useState, useEffect, useCallback } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { Iconify, Label } from "../../components";
import { capitalize } from "lodash";
import * as API from "../../ApiUrl";
import {
  Box,
  Tab,
  Tabs,
  Stack,
  Skeleton,
  styled,
} from "@mui/material";
import back from "../../assets/img/icons/back.svg";
import { toast } from "react-toastify";
import { merchantApi } from "../../redux/api/apiConfig";
import {
  CONTRACTS,
} from "../../ApiUrl";
import usePageTitle from "../../hooks/usePageTitle";
import ContractInfo from "./components/contractInfo";
import ContractDocument from "./components/contractDocument";

const ContractDetails = () => {
  const [currentTab, setCurrentTab] = useState("details");
  const [loading, setLoading] = useState(false);
  const [contractDetail, setContractDetail] = useState(null);
  const [supplierList, setSupplierList] = useState([]);
  const [lenderList, setLenderList] = useState([]);

  const location = useLocation();
  const history = useHistory();
  // const params = useParams();
  const query = new URLSearchParams(location.search);

  const filters = Object.fromEntries(query);
  const { id } = filters;

  usePageTitle("Contract Details");

  async function getContractDetail() {
    try {
      setLoading(true);
      const response = await merchantApi.get(`${CONTRACTS}${id}/`);
      console.log(response);
      setContractDetail(response.data);
      setLoading(false);
    } catch (error) {
      toast.error(error?.data?.data?.message || "Error getting contract data");
      setLoading(false);
    }
  }

  async function getSupplierList() {
    merchantApi
      .get(`${`${API.CONTRACTS_SUPPLIERS}?type=supplier`}`)
      .then((response) => {
        if (response.data) {
          setSupplierList(response.data);
        }
      })
      .catch((error) => {
        toast.error(
          error?.data?.data?.message || "Error getting supplier list"
        );
      });
  }

  async function getLenderList() {
    merchantApi
      .get(`${`${API.CONTRACTS_SUPPLIERS}?type=lender`}`)
      .then((response) => {
        if (response.data) {
          setLenderList(response.data);
        }
      })
      .catch((error) => {
        toast.error(error?.data?.data?.message || "Error getting lender list");
      });
  }

  useEffect(() => {
    getContractDetail();
    getSupplierList();
    getLenderList();
  }, [id]);

  const PREVIEW_TABS = [
    {
      value: "details",
      icon: <Iconify icon={"ic:round-account-box"} width={20} height={20} />,
      component: (
        <ContractInfo
          selectedContract={contractDetail}
          supplierList={supplierList}
          lenderList={lenderList}
        />
      ),
    },
    {
      value: "Documents",
      icon: <Iconify icon={"ion:documents"} width={20} height={20} />,
      component: (
        <ContractDocument
          contractDocument={contractDetail?.contract_document}
          contractId={contractDetail?.id}
          getContractDetail={getContractDetail}
        />
      ),
    },
  ];

  const backClickHandler = () => {
    history.goBack();
  };

  return (
    <Box sx={{ padding: "24px " }}>
      {loading ? (
        <Skeleton variant="rounded" animation={"wave"} height={1500} />
      ) : (
        <>
          <Stack justifyContent={"space-between"} direction={"row"}>
            <span onClick={backClickHandler} className="back mb-4">
              <img src={back} alt="back" />
              <span>Back</span>
            </span>
          </Stack>

          <Stack
            justifyContent={"space-between"}
            alignItems={{ md: "center", xs: "start" }}
            direction={{ md: "row", xs: "column" }}
            spacing={2}
          >
            <Tabs
              allowScrollButtonsMobile
              variant="scrollable"
              scrollButtons="auto"
              value={currentTab}
              onChange={(e, newValue) => setCurrentTab(newValue)}
              sx={{ background: "#f4f4f4", width: "100%" }}
            >
              {PREVIEW_TABS.map((tab) => (
                <Tab
                  disableRipple
                  key={tab.value}
                  label={capitalize(tab.value)}
                  iconPosition="start"
                  icon={tab.icon}
                  value={tab.value}
                />
              ))}
            </Tabs>
          </Stack>
          {PREVIEW_TABS.map((tab) => {
            const isMatched = tab.value === currentTab;
            return isMatched && <Box key={tab.value}>{tab.component}</Box>;
          })}
        </>
      )}
    </Box>
  );
};

export default ContractDetails;
