import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { ButtonPrimary, TextFieldSelect } from "../../components";
import "react-toastify/dist/ReactToastify.css";
import "react-phone-input-2/lib/style.css";
import { MenuItem } from "@material-ui/core";
import { Stack } from "@mui/material";
import * as API from "../../ApiUrl";
import { merchantApi } from "../../redux/api/apiConfig";
import { toast } from "react-toastify";

const numricRegex = /(?=.*[0-9])/;
const validationSchema = yup.object({
  status: yup.string("Select Status").required("Status is required"),
});

const KycKybStatusUpdate = ({ data, id, isKyb }) => {
  const [loading, setloading] = useState(false);
  const [kycStatus, setKycStatus] = useState(
    data?.options?.map((item) => ({ name: item?.display, value: item?.value }))
  );

  const onSubmit = async () => {
    const data = { ...formik.values };
    try {
      setloading(true);
      const url = isKyb ? API.UPDATE_KYB : API.UPDATE_KYC;
      const respose = await merchantApi.patch(`${url}${id}/`, data);
      toast.success(
        isKyb ? "KYB updated  successfully" : "KYC updated successfully"
      );
      setloading(false);
    } catch (error) {
      setloading(false);
      toast.error(
        error?.response?.data?.status[0] || "Error while updating status"
      );
    }
  };

  // const getCountriesData = async () => {
  //   try {
  //     const res = await merchantApi.get(`${API.KYB_STATUS}`);
  //     console.log("kyb status", res);
  //     setKycStatus(
  //       res?.data?.map((item) => ({ name: item?.value, value: item?.key }))
  //     );
  //   } catch (err) {
  //     console.log(err, "errr");
  //   }
  // };

  // useEffect(() => {
  //   getCountriesData();
  // }, []);

  const formik = useFormik({
    initialValues: {
      status: data?.value || "",
    },
    validateOnBlur: true,
    validationSchema: validationSchema,
    onSubmit,
    // isInitialValid: false,
    validateOnMount: true,
  });

  return (
    <>
      <Stack flexDirection={"row"} alignItems={"flex-end"} gap={"20px"}>
        <TextFieldSelect
          formik={formik}
          name={"status"}
          //   type={"text"}
          label={isKyb ? "KYB Status" : "KYC Status"}
          options={kycStatus}
          render={(role) => <MenuItem value={role.value}>{role.name}</MenuItem>}
        />
        <ButtonPrimary onClick={onSubmit} disabled={!formik.isValid || loading}>
          Update Status
        </ButtonPrimary>
      </Stack>
    </>
  );
};

export default KycKybStatusUpdate;
