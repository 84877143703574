import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Label } from "../../../../components";
import {
  Box,
  Stack,
  Typography,
  LinearProgress,
  Avatar,
  Tooltip,
} from "@mui/material";
import { rowsPerPageOptions, tableCellStyle } from "../../../../utils/common";
import { capitalize } from "lodash";
import createAvatar from "../../../../utils/createAvatar";
import { useHistory } from "react-router-dom";
import { tableStyle } from "../../../../utils/common";
import { Routes } from "../../../../routes";
import { format } from "date-fns";
import { fCurrency } from "../../../../utils/formatNumber";

const SuppliersTable = ({
  users,
  paginateTable,
  setPaginateTable,
  fetchUsersLoading,
}) => {
  const history = useHistory();
  const NoRowsOverlay = () => {
    return (
      <Stack height="100%" alignItems="center" justifyContent="center">
        {paginateTable.search === "" ? "No Clients Added" : "No Clients Found"}
      </Stack>
    );
  };

  const createDataRow = (user) => {
    // console.log('created data', user)
    return {
      id: user?.id,
      regDate: user?.created_date
        ? format(new Date(user?.created_date), "MM-dd-yyyy")
        : null,
      businessName: capitalize(user?.business_name),
      no: user?.sub_account?.name,
      balance: user?.sub_account?.balance,
      primaryShareholder: capitalize(
        `${user?.adminshareholder?.first_name} ${user?.adminshareholder?.last_name}`
      ),
      status: user?.kyb_status,
    };
  };

  const rows =
    users?.results?.length > 0
      ? users.results.map((user) => createDataRow(user))
      : [];

  const columns = [
    {
      field: "id",
      headerName: "ID",
      // flex: 1,
      minWidth: 80,
      // maxWidth: 80,
      headerClassName: "header",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Typography variant="body2" style={tableCellStyle}>
            {params.formattedValue}
          </Typography>
        );
      },
      // align: 'center',
    },
    {
      field: "regDate",
      headerName: "Registration Date",
      sortable: false,
      flex: 1,
      minWidth: 170,
      headerClassName: "header",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Typography variant="body2" sx={tableCellStyle}>
            {params.formattedValue || "--"}
          </Typography>
        );
      },
    },
    {
      field: "businessName",
      headerName: "Business Name",
      sortable: false,
      flex: 1,
      minWidth: 300,
      // maxWidth: 250,
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <Typography variant="body2" sx={tableCellStyle}>
            {params.formattedValue}
          </Typography>
        );
      },
      // align: 'center',
      // headerAlign: 'center',
    },
    {
      field: "no",
      headerName: "Sub Account No.",
      flex: 1.5,
      minWidth: 250,
      // maxWidth: 200,
      textOverflow: "ellipsis",
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <Tooltip sx={{ cursor: "default" }} title={params.formattedValue}>
            <Typography variant="body2" style={tableCellStyle}>
              {params.formattedValue}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "balance",
      headerName: "Balance",
      flex: 1.5,
      minWidth: 200,
      // maxWidth: 150,
      headerClassName: "header",
      renderCell: (params) => {
        return fCurrency(params.formattedValue);
      },
    },
    {
      field: "primaryShareholder",
      headerName: "Primary Shareholder",
      sortable: false,
      flex: 1,
      minWidth: 300,
      // maxWidth: 250,
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <Stack direction={"row"} sx={{ minWidth: 0, alignItems: "center" }}>
            <Avatar
              alt={params.formattedValue}
              sx={{ mr: 2, bgcolor: createAvatar(params.formattedValue).color }}
            >
              {createAvatar(params.formattedValue).name}
            </Avatar>
            <Typography
              variant="body2"
              sx={{
                color: "black",
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontWeight: "500",
              }}
            >
              {params.formattedValue}
            </Typography>
          </Stack>
        );
      },
      // align: 'center',
      // headerAlign: 'center',
    },
    {
      field: "status",
      headerName: "KYB Status",
      sortable: false,
      // flex: 1,
      minWidth: 300,
      alignItems: "center",
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <Label
            variant={"ghost"}
            color={
              params.formattedValue.value === "complete"
                ? "complete"
                : params.formattedValue.value === "not_done"
                ? "rejected"
                : params.formattedValue.value === "rejected"
                ? "rejected"
                : "application submitted"
            }
            sx={{ textTransform: "capitalize" }}
          >
            {params.formattedValue?.display}
          </Label>
        );
      },
    },
  ];

  const handleChangePage = (newPage) => {
    setPaginateTable({ ...paginateTable, currentPage: newPage });
  };

  const handleChangeRowsPerPage = (newSize) => {
    setPaginateTable({
      ...paginateTable,
      currentPage: 0,
      pageSize: newSize,
    });
  };

  return (
    <>
      <Box
        sx={{
          height: 615,
          width: 1,
          "& .MuiDataGrid-virtualScrollerRenderZone": {
            "& .MuiDataGrid-row": {
              // backgroundColor: 'rgba(235, 235, 235, .7)',
              fontWeight: 500,
              color: "#667085",
              fontFamily: "Inter, sans-serif",
              fontSize: "14px",
            },
          },
          "& .header": {
            backgroundColor: "#F4F4F4",
            "& div": {
              "& div": {
                "& div": {
                  "& div": {
                    fontSize: "14px",
                    fontWeight: 600,
                    fontFamily: "Inter, sans-serif",
                  },
                },
              },
            },
          },
        }}
      >
        <DataGrid
          autoHeight
          sx={{ ...tableStyle, cursor: "pointer" }}
          onRowClick={(row) => {
            history.push({
              pathname: Routes.SupplierDetail.path,
              search: `?id=${row.id}`,
            });
          }}
          rows={rows}
          columns={columns}
          rowHeight={72}
          disableColumnFilter={true}
          disableColumnMenu={true}
          pagination
          paginationMode="server"
          rowsPerPageOptions={rowsPerPageOptions}
          loading={fetchUsersLoading}
          pageSize={paginateTable.pageSize}
          page={paginateTable.currentPage}
          rowCount={users.count}
          onPageChange={(newPage) => handleChangePage(newPage)}
          onPageSizeChange={(newSize) => handleChangeRowsPerPage(newSize)}
          components={{ NoRowsOverlay, LoadingOverlay: LinearProgress }}
        />
      </Box>
    </>
  );
};

export default SuppliersTable;
